@import "./datePicker.css";
@import "./colors.css";
#awesome-pdf-viewer > .main {
    height: calc(100vh - 149px) !important;
}

    #awesome-pdf-viewer > .main > .right-panel {
        position: relative;
    }

    #awesome-pdf-viewer > .main > .left-panel {
        overflow-x: hidden;
    }


#awesome-pdf-viewer-organizer > .main {
    height: calc(100vh - 149px) !important;
}

    #awesome-pdf-viewer-organizer > .main > .right-panel {
        position: relative;
    }

    #awesome-pdf-viewer-organizer > .main > .left-panel {
        overflow-x: hidden;
    }

.review-main-container {
    display: flex;
}

.review-left-panel {
    overflow: auto;
    background-color: white;
    margin: auto;
}

.summary-step-container {
    height: 100%;
    min-height: 500px;
}

.completed-summary-step-container {
    height: calc(100vh - 80px) !important;
    min-height: 500px;
}

.content-left {
    /* padding-top: 20px;*/
    height: 96%;
}

.pay-content-top {
    padding-top: 20px;
}

.confirm-btn{
    color:white
}

.confirm-btn:hover {
    color: white;
    text-decoration: none;
}

.content-right {
    /*margin-left: 25px;*/
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
}

.payment-step-container {
    display: flex;
    height: 97%;
    margin-top: 10px;
}

.tax-payment-container {
    background-color: white;
    height: 100%;
}

.payment-refund-container {
    height: 50%;
    margin-right: 0px !important;
    padding-right: 0px !important;
}

.payment-info-modal-title {
    width: 100%;
}

.step-layout {
    height: 100%;
}

    .step-layout.with-footer {
        height: calc(100vh - 112px) !important;
    }



    .step-layout.left {
        float: left;
    }

    .step-layout.center {
        margin: auto;
    }

    .step-layout.right {
        float: right;
    }

.step-layout-header {
    padding: 10px;
    font-size: 20px;
    height: 50px;
    background-color: white;
}

.step-layout-footer, .step-layout-top-bar {
    margin: auto;
    background-color: white;
}

.step-layout-footer {
    border-top: 1px solid #dbdbdb;
}

.step-layout-top-bar {
    padding: 14px !important;
    height: auto;
    /*max-height: 200px;*/
    min-height: 50px;
    overflow: auto;
}

.pay-step {
    display: inline-flex;
}

.step-layout-left-panel-header, .step-layout-body-header {
    font-size: 20px;
    min-height: 65px;
    padding: 10px 15px 0px 15px;
    font-weight: bolder;
    height: auto;
    background-color: white;
}

    .step-layout-body-header > .sub-title {
        font-weight: bold;
        font-size: 14px;
    }

.step-layout-left-panel-body {
    padding: 0px 15px 0px 0px;
}

.step-layout-body {
    padding: 0px 15px 0px 15px;
}

.step-layout-left-panel-body, .step-layout-body {
    height: calc(100%);
    overflow-y: auto;
    overflow-x: hidden;
}

    .step-layout-body.without-header {
        height: 100% !important;
    }

.step-layout-body {
    width: 100%;
}


.step-layout-content {
    min-height: 450px;
    height: 100%;
}

.step-layout-header + .step-layout-content {
    height: calc(100% - 50px);
}


.step-layout-body-container {
    margin: 0px auto 0px auto;
}

.step-layout-left-panel {
    background-color: white;
}

.step-layout-left-panel, .step-layout-body-container {
    border: 1px solid #eeeeee;
    border-radius: 2px;
    height: 100%;
}

.step-layout-body.white {
    background-color: white;
}

.step-layout-body.transparent {
    background-color: transparent;
}

.custom-button {
    background-color: #5ffc5f1c;
    border: 2px solid #83c786;
    height: 65px;
    border-radius: 5px;
    display: flex;
    padding: 8px;
    cursor: pointer;
}

    .custom-button.left {
        float: left;
    }

    .custom-button.center {
        margin: auto;
    }

    .custom-button.xl {
        width: 250px;
    }

    .custom-button.lg {
        width: 225px;
    }

    .custom-button.md {
        width: 175px;
    }

    .custom-button.sm {
        width: 125px;
    }

    .custom-button.right {
        float: right;
    }


    .custom-button:hover {
        background-color: #9fff9f6b;
    }

.custom-button-icon-container {
    width: 25%;
}

.custom-button-text-container {
    width: 75%;
    overflow: hidden;
    user-select: none;
    color: #0274bb;
    font-weight: bold;
    font-size: 14px !important;
    margin: auto auto auto 10px;
    font-family: 'Roboto', sans-serif;
}




/* Custom button large */


.custom-large-button {
    background-color: #a3f7a31c;
    border: 2px solid #83c786;
    height: 130px;
    border-radius: 5px;
    padding: 8px;
    cursor: pointer;
    width: 250px;
}

    .custom-large-button.left {
        float: left;
    }

    .custom-large-button.center {
        margin: auto;
    }

    .custom-large-button.right {
        float: right;
    }


    .custom-large-button:hover {
        background-color: #cafdca6b;
    }

.custom-large-button-icon-container {
    width: 100%;
    height: 50%;
    margin: auto;
    margin-top: 15px;
}

    .custom-large-button-icon-container > svg {
        width: 100%;
        height: 100%;
    }


.custom-large-button-text-container {
    overflow: hidden;
    user-select: none;
    color: #0274bb;
    font-weight: bolder;
    font-size: 14px !important;
    width: 100%;
    text-align: center;
    margin-top: 10px;
}

/* Custom button large end */

.upload-documents-container {
    height: calc(100% - 25px);
    padding: 10px;
}

.upload-documents-area {
    height: calc(91% - 100px);
}

.download-documents-area {
    height: 100px;
}

.upload-documents-title {
    margin: 15px 0px 5px 0px;
}

.upload-documents-progress {
    top: 5px;
    position: relative;
    color: #0274bb;
}

.upload-documents-progress-conatiner {
    border: 1px solid #b4b4b4;
    border-radius: 61px;
    font-size: 10px;
    height: 25px;
    width: 25px;
    margin: auto;
}

.organizer-upload-documents-progress-conatiner {
    border: 1px solid #b4b4b4;
    border-radius: 61px;
    font-size: 10px;
    height: 25px;
    width: 25px;
    text-align: center;
}

.text-blue {
    color: #0274bb !important;
}

.company-info > .company-address {
    height: 65%;
    min-height: 100px;
}

.download-documents-area > .downlod-button-container {
    margin: 10px 0px 0px 0px;
}

.company-info > .company-fax {
    height: 35%;
    min-height: 100px;
}

#document-upload {
    height: 200px;
}

    #document-upload.with-border {
        border: 3px dashed #9ed4a0;
    }

.upload-area {
    height: 100px;
    padding: 47px 0px 0px 0px;
    text-align: center;
    cursor: pointer;
}

    .upload-area.minimized {
        border: 3px dashed #9ed4a0;
        margin-bottom: 20px;
        padding: 40px 0px 0px 0px !important;
    }

.uploaded-files-list {
    height: 100%;
    overflow: auto;
    padding: 5px;
}

    .uploaded-files-list.minimized {
        border: 2px solid #9ed4a0;
    }

.file-list-container {
    height: 200px;
    overflow: auto;
}

    .file-list-container > p {
        margin-bottom: 2rem;
    }

.file-list-item {
    border-top: 1px solid #cbd2d9;
    display: flex;
    justify-content: space-between;
    padding: 1.5rem 2rem;
}

.file-list-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

    .file-list-item .icon {
        font-size: 2.4rem;
        margin-right: 1rem;
    }

.manual-sign-uploads-table-action-btn {
    color: #8BC34A;
    font-weight: bold;
}



ul.task-items {
    padding: 0px !important;
}

    ul.task-items li.item {
        display: -webkit-box;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
       
        padding: 3px;
        background: #fff;
        box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
    }

        ul.task-items li.item.type1 .task .icon {
            cursor: pointer;
        }



        ul.task-items li.item .task {
            display: -webkit-box;
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            width: 95%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

            ul.task-items li.item .task .icon {
                width: 33px;
                height: 31px;
                border-radius: 20px;
                border-color: grey;
                border: 0px solid grey;
                padding: 5px 0px 0px 9px;
            }

            ul.task-items li.item .task .name {
                margin-left: 0px;
                width: 100%;
                height: 25px;
                max-width: 0px;
            }

.uploaded-date-mobile {
    margin-left: 10px;
    font-size: smaller;
    display: none;
}


ul.task-items li.item .dates {
    text-align: center;
    width: 15%;
    min-width: 90px;
}

    ul.task-items li.item .dates .bar,
    ul.task-items li.item .priority .bar {
        height: 25px;
        border-radius: 15px;
    }

ul.task-items li.item .priority {
    text-align: center;
    width: 15%;
}

.drop-area-highlight {
    background: #fff;
    box-shadow: 0 0 5px 10px rgba(149, 255, 26, 0.22);
}

.btn-ssr {
    background-color: white;
    color: #017cff;
    border-color: #28a745;
    border-radius: 20px;
    height: 35px;
    border: 1px solid #28a745;
    font-weight: bold;
    padding: 0px 15px 0px 15px;
}

.disable-btn-ssr {
    background-color: white;
    border-color: #28a745;
    border-radius: 20px;
    height: 35px;
    border: 1px solid #28a745;
    font-weight: bold;
    padding: 0px 15px 0px 15px;
    color: rgba(16, 16, 16, 0.3);
}

.step-icon {
    height: 100%;
    width: 100%;
}

/* Pay Step - START*/

.pay-content-right {
    height: auto;
    background-color: white;
    overflow: auto;
    border-top: 1px solid #eeeeee;
    height: 100%;
}

.pay-content-left {
    min-width: 260px;
    height: 100%;
}


.voucher-detail-container {
    height: 100%;
}

    .voucher-detail-container .top-div {
        padding-left: 10px;
        padding-right: 10px;
        padding-bottom: 10px;
        height: 90px;
        width: 100%;
    }

.voucher-dropdown {
    display: inline-flex;
}

    .voucher-dropdown .voucher-checkbox {
        padding-right: 5px;
        height: 30px;
    }

    .voucher-dropdown .dropdown {
        display: inline;
    }

    .voucher-dropdown .dropdown-toggle {
        width: 70px;
        text-align: center;
    }

    .voucher-dropdown .scrollable-menu {
        height: auto;
        max-height: 200px;
        overflow-y: scroll;
        width: 68px;
    }

    .voucher-dropdown .show.dropdown {
        display: inline !important;
    }

.voucher-list-div {
    border: 2px solid #ccc;
    border-radius: 16px;
    padding: 10px;
    position: relative;
    width: 100%;
    overflow-x: auto;
    height: 80%;
    margin: inherit;
}

    .voucher-list-div .section-heading, .pay-left-panel-list .section-heading {
        color: #0973ba;
        text-align: center;
        margin-bottom: 3%;
        font-weight: 600;
    }

.pay-left-panel-list {
    padding: 30px 20px 0px 20px;
    height: calc(100% - 75px);
}

.pay-screen-voucher {
    padding: 0;
    list-style-type: none;
}

section > .section-title {
    color: #0973ba;
    font-size: 15px;
    color: #0973ba;
}

.pay-left-panel {
    font-weight: bold;
    font-size: 14px;
}

.pay-screen-right-pointer {
    position: relative;
    background: var(--headerBgColor);
    height: 45px;
    line-height: 40px;
    margin-bottom: 20px;
    vertical-align: middle;
    color: white !important;
    box-shadow: 3px 5px 5px rgba(0, 0, 0, 0.2);
    width: 100% !important;
}

.pay-content-right .pay-display-box {
    border: 2px solid #ccc;
    height: 235px;
    width: 175px;
    border-radius: 5px;
    float: left;
    margin: 14px;
}

.pay-content-right .pay-box {
    border: 2px solid #ccc;
    height: 235px;
    width: 175px;
    border-radius: 5px;
    float: left;
    margin: 25px;
}

.pay-content-right .pay-display-box.done {
    /* background: url("/Content/img/icons/check-mark.png") no-repeat top right; */
    background-size: 55px;
}

.pay-content-right .pay-display-box .pay-date {
    border: 1.5px solid #9E9E9E;
    border-radius: 5px;
    width: 115px;
    position: relative;
    top: -15px;
    left: -10px;
    background-color: #EDF7E6;
    padding: 2px;
    text-align: center;
}

.pay-content-right .pay-display-box .pay-icon {
    text-align: center;
    margin: 0 auto;
    height: 40%;
}

.pay-content-right .pay-display-box .pay-fee-name {
    text-align: center;
}

.pay-content-right .pay-display-box .pay-fee-amount {
    text-align: center;
    margin: 0 auto;
    font-weight: 600;
}

.pay-content-right .pay-display-box .pay-button {
    margin: 15px 0px 0px 35px;
    position: relative;
}

    .pay-content-right .pay-display-box .pay-button .btn-secondary {
        min-width: 0;
        border: 2px solid #8bc656;
        border-radius: 25px;
        background-color: #f3f9ee;
        color: #0973ba;
        font-weight: bold;
        min-width: 85px;
    }

.content-right .pay-display-box .pay-button .btn-secondary:hover {
    background-color: #e8f4dd;
}

.pay-content-right .pay-display-box .pay-button .btn-secondary:active {
    border: 2px solid #0973ba;
    background-color: #0973ba;
    color: #fff;
}

.pay-content-right .pay-display-box .pay-button .info {
    position: absolute;
    top: 6px;
    right: 9px;
}

/*Payment information popup custom classed*/
.pay-box {
    border: 2px solid #D0D0D0;
    border-radius: 5px;
    background-color: #FAFAFA;
    margin: 10px 0px 0px -9px;
    /*width: 220px;*/
    /*color: #0973ba;*/
    font-weight: bold;
    min-height: 64px;
    /*position: absolute;*/
    bottom: 30px;
    height: 315px;
    width: 46%;
    margin: auto;
    padding: 10px 10px;
}

.content-title {
    font-weight: bold;
    font-size: 13px;
    color: #0973ba;
    border-bottom: 1px dashed #E0E0E0;
    height: 40px;
}

.spacer {
    line-height: 36px;
}

.spacer1 {
    line-height: 20px;
}

.download-singlevoucher {
    margin-left: 153px;
    margin-top: -25px;
    padding-bottom: 29px;
    color: #0973ba;
}

.modal-body .pay-box .action-box-sm .box-image {
    float: left;
    width: 60px;
    padding-left: 10px;
    padding-top: 6px;
    margin-right: 10px;
}

.clearfix {
    clear: both;
}

.tColor {
    color: #0973ba;
    font-weight: bold
}

.modal-body .pay-box .action-box-sm p.pay-spacer {
    margin-top: 7%;
}

.modal-body .pay-box .action-box-sm.online-icon .box-image {
    padding-top: 10px;
}

.action-box-sm {
    min-height: 60px;
    max-width: 95%;
}

.pay-info-icon {
    cursor: pointer;
    border: 2px solid #8bc656;
    border-radius: 7px;
    margin-top: 68px;
    margin-right: 7px;
    max-width: 210px;
}

.pay-info-text {
    min-height: 140px;
    margin-top: 10px;
}

.pay-filing-instruction-link {
    float: right;
    margin-right: 10px;
}

.icon-expanded {
    cursor: pointer;
    border: 2px solid #8bc656;
    border-radius: 7px;
    margin-top: 20%;
    margin-right: 7px;
    max-width: 210px;
}

.icon-voucher-expanded {
    cursor: pointer;
    border: 2px solid #8bc656;
    border-radius: 7px;
    margin-top: 28%;
    margin-right: 7px;
    max-width: 210px;
}

.icon-payment-expanded {
    cursor: pointer;
    border: 2px solid #8bc656;
    border-radius: 7px;
    margin-top: 15%;
    margin-left: 52%;
    max-width: 210px;
}

.pay-table {
    line-height: 36px;
}

.pay-icon {
    text-align: center;
    margin: 0 auto;
}

.img-responsive {
    height: 50px;
    display: initial;
}

.pay-fee-name {
    text-align: left;
}

.large-text {
    font-size: 22px;
    margin-bottom: 10px;
}

.larger {
    font-size: 14px;
}

td.amount {
    padding-right: 10px;
}

.custom-model {
    cursor: pointer;
    border-radius: 5px;
    position: absolute;
    right: 20px;
    margin-right: 20px;
    margin-top: -27px;
    width: 193px;
    background-color: rgb(243,249,238);
    border: 1px solid lightgreen;
}

.btn-radius {
    border-radius: 20px !important;
}
/*Payment information popup custom classed*/
/* Pay Step - END*/
.k1download-button {
    background-color: #5ffc5f1c;
    border: 2px solid #83c786;
    height: 50px;
    border-radius: 5px;
    width: 180px;
    display: flex;
    padding: 5px;
    cursor: pointer;
}

.k1download-button-icon-container {
    width: 25%;
}

.k1download-button-text-container {
    width: 75%;
    overflow: hidden;
    user-select: none;
    color: #0274bb;
    font-weight: bolder;
}

.download-helper {
    position: absolute;
    z-index: 10000000;
}

.download-button-helper {
    position: absolute;
    right: 20px;
    border-radius: 5px;
}

.distributeK1Tbl ul.pagination {
    float: right;
}

.distributeManualTbl ul.pagination {
    float: right;
}

.manualsign-body {
    height: calc(100vh - 150px) !important;
    border: none;
}

.manualsign-footer {
    padding: 12px;
    height: 58px;
}

.myDownload-delete {
    margin-left: -130%;
    margin-top: 80%;
    color: indianred !important;
    cursor: pointer;
}

.pay-body {
    border: none;
    justify-content: center;
    min-height: 600px;
}

.k1distribution-body {
    border: none;
    justify-content: center;
    min-height: 600px;
    padding: 50px 0px 0px 0px;
}

.kba-wrapper {
    height: Calc(100% - 60px);
}

.margin-top-20 {
    margin-top: 20px !important;
}

.margin-bottom-10 {
    margin-bottom: 10px !important;
}

.margin-right-10 {
    margin-right: 10px !important;
}
.margin-top-10 {
    margin-top: 10px;
}

.source-download-all-btn > button {
    background-color: #21a9e1;
    font-size: 14px;
}

.cls-source-download, .cls-source-download:active, .cls-source-download:hover {
    color: white !important;
    background-color: rgb(33, 169, 225) !important;
}

    .react-checkbox-tree {
        display: block;
    }

.download-footer {
    border-radius: 5px;
    position: relative;
    margin: auto;
    background: #FFF;
    padding: 15px;
}

.btn.btn-primary.rounded, .btn.btn-secondary.rounded {
    color: white !important;
    min-width: 100px !important;
    border-radius: 5rem !important;
    border-color: rgb(159, 227, 80) !important;
}

    .btn.btn-primary.rounded i.fa.fa-save, .btn.btn-primary.rounded i.fa.fa-times {
        padding-right: 5px;
    }

.steps-navigation-item-main {
    border: none !important;
    background-color: none !important;
}


.content-wrapper .greeting {
    padding-top: 50px;
    padding-bottom: 60px;
    font-size: 22px;
}

.content-wrapper .greeting {
    padding-top: 50px;
    padding-bottom: 60px;
    font-size: 22px;
}

.organizer-welcome-page {
    padding-top: 60px !important;
    overflow: auto;
    height: 100vh;
}

.organizer-welcome-page-container {
    border-radius: 6px;
    background-color: #fff;
    z-index: 2;
    cursor: pointer;
    height: calc(100% - 115px);
    padding-top: 5%;
    padding-top: 50px;
    padding-bottom: 20px;
    overflow: auto;
}


.organizer-welcome-page-title-container {
    text-align: center;
}

.organizer-welcome-page-message-container {
    text-align: center;
}

.organizer-welcome-page-menu-container {
    text-align: center;
    user-select: none;
}

.organizer-welcome-page-title {
    font-size: 24px;
}

.organizer-welcome-page-message {
    font-size: 18px;
}

.organizer-welcome-page-menu-title {
    font-size: 15px;
}

.organizer-welcome-page-menu-icon-container {
    display: inline-flex;
}

.organizer-welcome-page-menu-separator {
    border: 1px solid #0973ba;
    margin: 50px auto auto auto;
    width: 150px;
    background-color: #0973ba;
}

.organizer-welcome-page-menu-icon-title {
    width: 100px;
    color: #0973ba;
    font-weight: 600;
}

.text-green {
    color: #88c656 !important;
}



.bookmark-list-item-list-conatiner {
    padding: 0px;
}


.bookmark-list-item-conatiner {
    height: 30px;
    padding: 7px 0px 5px 5px;
    border-bottom: 1px solid #e8e8e8;
    cursor: pointer;
    padding-left: 20px;
}

    .bookmark-list-item-conatiner:hover {
        background-color: #cecece69;
    }

    .bookmark-list-item-conatiner.selected {
        background-color: #cecece69;
    }

    .bookmark-list-item-conatiner img {
        margin-left: 5px;
    }

.bookmark-list-item {
    display: block;
    position: relative;
    /*padding-left: 22px;*/
    margin-bottom: 13px;
    cursor: pointer;
    font-size: 11px;
    user-select: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 65%;
    margin-left: 5px;
}

    /*    .bookmark-list-item:hover { 
        color: #9e9e9e;
    }
*/
    .bookmark-list-item input {
        position: absolute;
        z-index: -1;
        opacity: 0;
    }
    .bookmark-list-item span {
        margin-left: 5px;
    }

.bookmark-list-item__indicator {
    position: absolute;
    top: 0px;
    left: 0;
    height: 17px;
    width: 17px;
    background: #e6e6e6;
    border-radius: 15px;
}


.bookmark-list-item:hover input ~ .bookmark-list-item__indicator,
.bookmark-list-item input:focus ~ .bookmark-list-item__indicator {
    background: #ccc;
}

.bookmark-list-item input:checked ~ .bookmark-list-item__indicator {
    background: #88c656;
}

.bookmark-list-item:hover input:not([disabled]):checked ~ .bookmark-list-item__indicator,
.bookmark-list-item input:checked:focus ~ .bookmark-list-item__indicator {
    background: #88c656;
}

.bookmark-list-item input:disabled ~ .bookmark-list-item__indicator {
    background: #e6e6e6;
    opacity: 0.6;
    pointer-events: none;
}

.bookmark-list-item__indicator:after {
    content: '';
    position: absolute;
    display: none;
}

.bookmark-list-item input:checked ~ .bookmark-list-item__indicator:after {
    display: block;
}

.bookmark-list-item--checkbox .bookmark-list-item__indicator:after {
    left: 6px;
    top: 2px;
    width: 5px;
    height: 10px;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

.bookmark-list-item--checkbox input:disabled ~ .bookmark-list-item__indicator:after {
    border-color: #7b7b7b;
}

.bookmark-list-item-icon {
    color: #4CAF50;
}

.bookmark-list-item-page-info {
    color: #03A9F4;
    font-weight: 600;
}


.page > input::-webkit-outer-spin-button,
.page > input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
.page > input[type=number] {
    -moz-appearance: textfield;
}

.page > div > select {
    /* for Firefox */
    -moz-appearance: none !important;
    /* for Chrome */
    -webkit-appearance: none !important;
}

.page > input[type="date"]::-webkit-inner-spin-button,
.page > input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
}

.page > input[type=number] {
    -moz-appearance: textfield;
}

.page > input[type="date"]::-webkit-inner-spin-button,
.page > input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
}


.option-input {
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    appearance: none;
    position: relative;
    top: 13.33333px;
    right: 0;
    bottom: 0;
    left: 0;
    height: 40px;
    width: 40px;
    transition: all 0.15s ease-out 0s;
    background: #cbd1d8;
    border: none;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    margin-right: 0.5rem;
    outline: none;
    position: relative;
    z-index: 1000;
}

    .option-input:hover {
        background: #9faab7;
    }

    .option-input:checked {
        background: #40e0d0;
    }

        .option-input:checked::before {
            height: 40px;
            width: 40px;
            position: absolute;
            content: '✔';
            display: inline-block;
            font-size: 26.66667px;
            text-align: center;
            line-height: 40px;
        }

        .option-input:checked::after {
            -webkit-animation: click-wave 0.65s;
            -moz-animation: click-wave 0.65s;
            animation: click-wave 0.65s;
            background: #40e0d0;
            content: '';
            display: block;
            position: relative;
            z-index: 100;
        }

    .option-input.radio {
        border-radius: 50%;
    }

        .option-input.radio::after {
            border-radius: 50%;
        }

#awesome-pdf-viewer-organizer > .main > .page-viewer > .pdfViewer > .page > .textbox-control {
    background-color: #88c65626 !important;
    /*background-color: rgba(218, 237, 201, 1) !important;*/
    border: none !important;
}

    #awesome-pdf-viewer-organizer > .main > .page-viewer > .pdfViewer > .page > .textbox-control.textbox-control-highlight {
        background-color: #fff6db !important;
    }

#awesome-pdf-viewer-organizer > .main > .page-viewer > .pdfViewer > .page > div > .textbox-control {
    background-color: #88c65626 !important;
    border: none !important;
}

#awesome-pdf-viewer-organizer > .main > .page-viewer > .pdfViewer > .page > .textbox-control:focus {
    border: none !important;
    box-shadow: none !important;
    background-color: white !important;
}

.area-disabled {
    cursor: not-allowed;
    opacity: .3;
    pointer-events: none;
}

.link-disabled {
    color: #666666
}

.awesome-pdf-viewer-loader.visible {
    background-color: unset !important;
}
.btn-tablet-width-100{
    min-width: 130px !important;
}


.right-panel-container{
    display: flex;
    flex-direction: column;
    gap: calc(100vh - 40.6rem);
}

.col-hidden{
    display: none;
}

.uploaded-src-documents-list{
    margin-top: 0.5rem;
    list-style: none;
    padding-left: 0;
    font-size: 10px;
    border: 1px solid #ced4da;
    height: 119px;
    overflow-y: scroll;
}

.uploaded-src-documents-list li{
    display:flex;
    padding: 0.3rem 0.1rem 0.1rem 0.3rem;
    border-bottom: 1px solid #ced4da;
}

.uploaded-src-documents-list li .uploaded-src-file-name{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    text-align: left;
}
.uploaded-src-documents-list li:last-child{
    border-bottom: none;
}
.uploaded-src-preview-icon-container{
    margin-left: 10px;
    cursor: pointer;
}

.uploaded-src-preview-icon{
    font-size: 14px;
    color: #0873ba;
}

.uploaded-src-delete-icon-container{
    margin: 0 4px 0 10px;
    color: #c7441c;
    cursor: pointer;
}

.src-delete-icon-container{
    color: #c7441c;
    cursor: pointer;
    font-size: 0.8rem;
}

.disable-src-document-delete{
    cursor:not-allowed;
    color: grey;
}
.file-preview-modal {
    width: 800px !important;
    min-height: 700px;
    max-height: 90vh;
    height: auto;
    margin: auto;
}
.file-preview-modal .modal-dialog {
    max-width: 900px !important;
}
.file-preview-modal.center-modal {
    left: 50%;
    transform: translate(-50%);
}

.file-preview-modal .left-section {
    display: none;
}

.file-preview-modal .middle-section{
    margin-left: 5rem;
}

.file-preview-modal .middle-section .icon-bar a {
    margin:5px 0 0 0 !important
}

.file-preview-modal .right-section{
    display: none;
}

.file-preview-modal .modal-body {
    max-height: 800px;
    height:auto;
    padding: 0px !important;
}

.filePreviewModalContent {
    overflow-y: initial !important
}

.nopadding {
    padding: 0 !important;
}

.modal-header .modal-title {
    color: #1a8fbf;
    font-size: 16px;
    font-weight: bold;
}


.file-preview-modal .modal-header .modal-title {
    font-size: 14px !important;
    word-break: break-word
}

.TaxPayerNotes {
    position: absolute;
    bottom: 0px;
    width: 100%;
}
.source-document-upload-left-box{
    flex: 0 0 25%;
    max-width: 25%;
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
}
.source-document-upload-right-box{
    flex: 0 0 75%;
    max-width: 75%;
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
}

@media (max-width: 1366px) {
    .file-list-container {
        /* height: 150px; */
    }
    .source-document-upload-left-box{
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
    }
    .source-document-upload-right-box{
        flex: 0 0 66.666667%;
        max-width: 66.666667%;
    }
}

/* @media (min-width: 768px) {
    .modal-dialog {
        max-width: 900px;
    }
} */

.rct-node-icon {
    color: #717171;
}
li span.rct-text span.rct-node-clickable {
    text-overflow: ellipsis !important;
    overflow: hidden;
    display: inline-block;
    white-space: nowrap;
    width: 200px !important;
}
.rct-node-clickable:focus {
    outline: 0;
    background: transparent;
}
.file-node-selected {
    outline: 0;
    background: rgba(51, 51, 204, 0.3);
}

.Toastify__close-button{
    margin-left: 6px;
}


footer {
    z-index: 11;
}

/*Footer Bug Fix Start for Potrait Tablet*/
@media only screen and (max-width: 1038px){
    footer{
        position: fixed;
        bottom: 0px;
        width: 100%;
        z-index: 9;
    }
}
@media only screen and (max-width: 769px){
    footer{
        position: fixed;
        bottom: 0px;
        width: 100%;
        z-index: 9;
        height: 66px;
    }
}
/*End */

/* Footer Bug fix for Landscape tablet Start*/
/* @media only screen and (min-height : 480px) and (max-width : 1206px) and (orientation: landscape){
    footer{
        position: fixed;
        bottom: 0px;
        width: 100%;
        z-index: 100;
       
        
    }
    .shepherd-footer{
        position: static;
        width: initial;
    }
} */
@media only screen and (min-height : 544px) and (max-width : 1206px) and (orientation: landscape){
    footer{
        position: fixed;
        bottom: 0px;
        width: 100%;
        z-index: 9;
        /* height: 66px; */
    }
    .shepherd-footer{
        position: static;
        width: initial;
    }
    .shepherd-element {
        z-index: 11 !important;
    }
}
 
@media only screen and  (min-height : 950px) and (max-width : 1369px) and (orientation: landscape){
    footer{
        position: fixed;
        bottom: 0px;
        width: 100%;
        z-index: 9;
    }
    .shepherd-footer{
        position: static;
        width: initial;
    }
    .shepherd-element {
        z-index: 11 !important;
    }
}
/* End */

/*UI Bug (Next Indicator misplaced in Tablets) fix -Start*/

@media only screen and (max-width: 1038px){
    .shepherd-element {
        position: absolute !important;
        z-index: 11 !important;
      }
      .shepherd-footer{
        position: static;
        width: initial;
    }
    .organizer-welcome-page-menu-separator{
        width: 70px;
    }
}

/*End*/

/*UI Bug-Document Upload Landing Page Start*/
@media only screen and (max-width : 769px){
    
    .organizer-welcome-page-menu-icon-title {
        width: 100%;
    }
   
}

/*End*/
/* Right panel bug  Start*/
@media only screen and (max-width: 1038px){
    #awesome-pdf-viewer-organizer > .main > .right-panel {
        position: absolute;
    }
    .btn-tablet-width-100{
        min-width: 100px !important;
    }
    /* .Tablet-h5{
        font-size: 16px;
    }
    .Tablet-h6{
        font-size: 14px;
    } */
    .btn-tablet{
        padding: 0 8px;
        height: 26px;
    }
    .upload-area {
        padding: 47px 0px 0px 0px;
    }
    .Tablet-width-80_per{
        width: 80% !important;
    }
    .Tablet-width-10_per{
        width: 10%;
    }
    .Tablet-flex-between{
        justify-content: space-between;
    }
    .Tablet-hidden{
        display: none;
    }
    #awesome-pdf-viewer-organizer > .main > .left-panel {
        position: absolute;
        z-index: 3;
    }
}
@media only screen and  (min-height : 950px) and (max-width : 1369px) and (orientation: landscape){
    .btn-tablet-width-100{
        min-width: 100px !important;
    }
    .shepherd-element {
        z-index: 11 !important;
    }
}

@media only screen and (max-width : 769px){
    
    .btn-tablet-width-100{
        min-width: 85px !important;
    }
    .mt-6{
        margin-top: 6px;
    }
    .Tablet-h5{
        font-size: 16px;
    }
    .Tablet-h6{
        font-size: 14px;
    }
  
}
/* To Keep notes section above footer Start */
@media only screen and (max-width: 1038px){
    .TaxPayerNotes {
        position: absolute;
        bottom: 0px;
        width: 100%;
    }
}
/* End  */

/* Samsung Tablet Overflow UI Bug Start */
@media only screen and (max-width: 769px){
    #awesome-pdf-viewer-organizer > .main {
        height: calc(100vh - 240px) !important;
    }
}

/* End  */

/* UI Bug Right Panel and Footer For Landscape Start  */
/* @media only screen and (min-height : 480px) and (max-width : 1206px) and (orientation: landscape){
    #awesome-pdf-viewer-organizer > .main {
        height: calc(100vh - 240px) !important;
    }
    .btn-tablet-width-100{
        min-width: 85px !important;
    }
    #awesome-pdf-viewer-organizer > .main > .right-panel {
        position: absolute;
    }
    .TaxPayerNotes {
        position: absolute;
        bottom: 0px;
        width: 100%;
    }
    .side-panel {
        width: 225px;
    }
    .right-panel {
        height: calc(100% - 148px) !important;
    }  
} */
@media only screen and (min-height : 544px) and (max-width : 1206px) and (orientation: landscape){
    #awesome-pdf-viewer-organizer > .main {
        height: calc(100vh - 240px) !important;
    } 
    .btn-tablet-width-100{
        min-width: 85px !important;
    }
    #awesome-pdf-viewer-organizer > .main > .right-panel {
        position: absolute;
    }
    .TaxPayerNotes {
        position: absolute;
        bottom: 0px;
        width: 100%;
    }
    .side-panel {
        width: 225px;
    }
    .right-panel {
        height: calc(100% - 148px) !important;
    }
    #awesome-pdf-viewer-organizer > .main > .left-panel {
        position: absolute;
        z-index: 3;
    }
}
@media only screen and  (min-height : 949px) and (max-width : 1369px) and (orientation: landscape){
    /* .side-panel {
        width: 225px;
    } */
    .right-panel {
        height: calc(100% - 148px) !important;
    }
    #awesome-pdf-viewer-organizer > .main > .right-panel {
        position: absolute;
        width: 225px;
    }
    .TaxPayerNotes {
        position: absolute;
        bottom: 0px;
        width: 100%;
    }
    #awesome-pdf-viewer-organizer > .main > .left-panel {
        width: 265px;
        height: calc(100% - 50px) !important;
        position: absolute;
        z-index: 3;
    }
}
/* End */

/* Download Button not Available on smaller Tablets -Start */
@media only screen and (max-width: 769px){
    #awesome-pdf-viewer-organizer > .header > .toolbar{
        justify-content: space-between;
    }
    #awesome-pdf-viewer-organizer > .header > .toolbar > .left-section{
    
        max-width: unset;
        width: 16.66%;
        flex-basis: 5%;
        padding-right: 0px;
        
        }
        #awesome-pdf-viewer-organizer > .header > .toolbar > .middle-section{
           display: flex;
           justify-content: center;
            /* width: 66.66%; */
            max-width: unset;
            flex-basis: 70%;
            padding-left: 0;
            padding-right: 0;
        }
        
        #awesome-pdf-viewer-organizer > .header > .toolbar > .right-section{
            
                width: 16.66%;
                max-width: unset;
                flex-basis: 16.66%;
                padding-left: 0px;
        }
}
/* End */

/* Required Tooltip issue */
@media only screen and (max-width: 769px){
    .awesome-pdf-viewer-tooltip .tooltiptext{
        min-width: unset;
        padding-left: 4px;
        padding-right: 4px;
    }
}
/* @media only screen and (max-width: 769px){
    .pdfViewer .page{
        width: 587px !important;
        height: 830px !important;
    }
    .pdfViewer .page .canvasWrapper{
        width: 587px !important;
        height: 830px !important;
    }
    .pdfViewer .page .textLayer{
        width: 587px !important;
        height: 830px !important;
    }
} */


@media only screen and (min-height : 544px) and (max-width : 1206px) and (orientation: landscape){
    .organizer-welcome-page {
        padding-top: 30px !important;
        overflow: auto;
        height: 100vh;
    }
    .file-preview-modal.center-modal{
        transform: none !important;
    }
}
/* Blurry logo bug fix */
.prepared-by-img{
max-width: 100%;
max-height: unset;
height: 100%;
object-fit: contain;
}

/* ----------Welcome Screen Bug Fix for ipAD Pro ---------- */

  @media only screen and (max-width: 1038px){
    .organizer-welcome-page-container{
        padding-top: 18%;
    }
    .organizer-welcome-page-title {
        font-size: 26px;
    }
    
    .organizer-welcome-page-message {
        font-size: 20px;
    }
    
    .organizer-welcome-page-menu-title {
        font-size: 17px;
    }
    .organizer-welcome-page-menu-icon-container {
        margin-top: 2%;
        margin-bottom: 2%;
    }
    .tablet-btn{
        font-size: 16px !important;
    }
}

@media only screen and  (min-height : 949px) and (max-width : 1369px) and (orientation: landscape){
    .organizer-welcome-page-container{
        padding-top: 12%;
    }
    .organizer-welcome-page-title {
        font-size: 26px;
    }
    
    .organizer-welcome-page-message {
        font-size: 20px;
    }
    
    .organizer-welcome-page-menu-title {
        font-size: 17px;
    }
    .organizer-welcome-page-menu-icon-container {
        margin-top: 2%;
        margin-bottom: 2%;
    }
    .tablet-btn{
        font-size: 16px !important;
    }
}

.datepicker-taxpayer {
    color: var(--taxpayer-color);
}

.datepicker-spouse {
    color: var(--spouse-color);
}

.checkbox-taxpayer {
    accent-color: var(--taxpayer-color);
}

.checkbox-spouse {
    accent-color: var(--spouse-color);
}

.awesome-pdf-viewer-radio-button:checked[data-color="#542D87"]+.awesome-pdf-viewer-radio-button-label[data-color="#542D87"]:before {
    color: var(--taxpayer-color) !important;
}

.awesome-pdf-viewer-radio-button:checked[data-color="#5C853A"]+.awesome-pdf-viewer-radio-button-label[data-color="#5C853A"]:before {
    color: var(--spouse-color) !important;
}

.preparer-message-container {
    height: 200px;
    overflow: hidden;
    border-style: solid;
    border-width: 3px;
    border-color: #0973ba;
    border-radius: 15px;
    margin-left: 100px;
    margin-right: 100px;
}

.preparer-message-content {
    height: 200px;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 20px;
    padding-bottom: 60px;
  }

.preparer-message-content > p {
    font-family: 'Poppins', sans-serif;
}

.prepare-message-header {
    padding-top: 10px;
    font-weight: bold;
    padding-left: 20px;
}

.custom-download-type {
    width: 440px !important;
}
.custom-download-type > .Select-control {
        font-size: 13px !important;
    }
.padding-bottom-0 {
    padding-bottom :0px
}
.height-20p{
    height:20px !important;
}
.no-top-padding {
    padding-top: 0px;
}
#react-doc-viewer {
    height: 600px;
}

#react-doc-viewer iframe#msdoc-iframe {
    height: calc(100% + 26px)
}

#react-doc-viewer #proxy-renderer{
 overflow: hidden !important;
}
.pg-viewer-wrapper video {
    width: 100%;
    height: 100%;
}

#react-doc-viewer #image-renderer {
    background-image: none !important;
}

@media (min-width: 1366px) {
    #react-doc-viewer {
        height: 520px !important;
        overflow-y: auto !important;
    }    
}

@supports not (-webkit-touch-callout: none) {
    /* CSS for other than iOS devices */
    @media screen and (min-device-width: 1300px) and (orientation:landscape) {
        #react-doc-viewer{
            height: 470px;
        }
    }

    @media screen and (max-device-width: 1250px) and (orientation:landscape) {
        .file-preview-modal #awesome-pdf-viewer > .main{
            height: calc(100vh - 240px) !important;
        }

        #react-doc-viewer{
            height: 470px;
        }

        .upload-documents-container{
            margin-bottom: 70px;
        }

        .file-preview-modal{
            left: 15% !important;
        }
    }

    @media screen and (min-device-width: 576px) and (max-device-width: 1000px){
        .file-preview-modal .modal-dialog {
            max-width: 610px !important;
        }
    }    
}

/* Photo document viewer support start */

.photo-viewer-container {
    margin: 0 auto;
    width: 90% !important;
    height: 90% !important;
}

.photo {
    width: 100% !important;
    height: 100% !important;
}

#pdf-page-wrapper .react-pdf__Page {
    height: 95% !important;
}
    /* Photo document viewer support end */

@media only screen and (max-width : 769px) {
    .file-preview-modal {
        left: 5% !important;
        transform: none !important;
        width: 90% !important;
    }   
}

.font-size-14p {
    font-size: 14px;
}

.font-size-16p {
    font-size: 16px;
}

.font-weight-bold {
    font-weight: bold;
}
.padding-bottom-4 {
    padding-bottom: 4px;
}
.margin-top-4 {
    margin-top: 4px !important;
}
.margin-top-3 {
    margin-top: 3px !important;
}
.uploaded-src-documents-indicator {
    color: black;
    font-size: 12px;
    font-family: sans-serif;
}

.source-upload-preview > .modal-dialog {
    max-width: 900px !important;
}

@media (min-width: 576px){
    .bootbox > .modal-dialog {
        max-width: 570px;
        margin: 1.75rem auto;
    }
} 

/* CSS for IPad devices */
@media only screen and (max-width: 1038px) and (orientation: portrait) {
    .react-datepicker-wrapper,
    .react-datepicker__input-container,
    .datepicker-control-input {
      font-size: 10px !important;
      padding: 0px 1px;
    }
  }
  
  @media only screen and (max-width: 1367px) and (orientation: landscape) {
    .react-datepicker-wrapper,
    .react-datepicker__input-container,
    .datepicker-control-input {
      font-size: 10px !important;
      padding: 0px 1px;
    }
    footer{
        position: fixed;
        bottom: 0px;
        width: 100%;
        z-index: 9;
    }
  }